import $ from 'jquery';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BASE_URL_API, BASE_URL_NODE_API } from 'assets/js/baseUrl';

export const SET_TO_HISTORY_ROUT = 'SET_TO_HISTORY_ROUT';
export function setToHistoryRout(status) {
	return {
		type: SET_TO_HISTORY_ROUT,
		status: status
	}
}

export function checkPhone(phone, historyArr) {
	return (dispatch, getState) => {
		let state = getState();
		$.ajax({
			type: "POST",
			async: false,
			url: BASE_URL_API + '/terminal/check_phone',
			dataType: "json",
			data: 'DATA=' + JSON.stringify({ accessToken: getState().authReducer.accessToken, phone }),
			success: data => {
				if (data.phone === 0) {
					if (state.appReducer.toHistoryRout) {
						toast.info(`Данный номер ${phone} не зарегистрирован!`);
					} else {
						dispatch(savePhone(phone));
						dispatch(saveBalances(0, 0));
						historyArr.push('/gender_age')
					}
				} else {
					dispatch(savePhone(phone));
					dispatch(saveBalances(+data.balance, +data.creative_haircut_balance));
					dispatch(saveGenderAge(0, 0));

					if (state.appReducer.toHistoryRout) {
						historyArr.push('/history')
					} else {
						historyArr.push('/services')
					}
				}
			},
			error: (error) => {
				console.log(error);
			}
		});
	}
}

export function getTurnData() {
	return (dispatch, getState) => {
		$.ajax({
			type: "POST",
			async: false,
			url: BASE_URL_API + '/terminal/get_turn_data',
			dataType: "json",
			data: 'DATA=' + JSON.stringify({ accessToken: getState().authReducer.accessToken }),
			success: data => {
				dispatch(saveTurnData(data.turn_num, data.date_time))
			},
			error: error => {
				console.log(error);
			}
		});
	}
}

export const SAVE_TURN_DATA = 'SAVE_TURN_DATA';
export function saveTurnData(turnNum, dateLastServ) {
	return {
		type: SAVE_TURN_DATA,
		turnNum,
		dateLastServ
	}
}

export const SAVE_PHONE = 'SAVE_PHONE';
export function savePhone(phone) {
	return {
		type: SAVE_PHONE,
		phone
	}
}

export const SAVE_GENDER_AGE = 'SAVE_GENDER_AGE';
export function saveGenderAge(gender, age) {
	return {
		type: SAVE_GENDER_AGE,
		gender,
		age
	}
}

export const APP_CLEAR = 'APP_CLEAR';
export function appClear() {
	return {
		type: APP_CLEAR,
	}
}

export function sendOrderData(services, historyObj, subminButtonRef) {
	return (dispatch, getState) => {

		subminButtonRef.disabled = true;

		let accessToken = getState().authReducer.accessToken;
		let turnNum = +getState().appReducer.turnNum;
		let dateLastServ = getState().appReducer.dateLastServ;
		let userData = {
			phone: getState().appReducer.phone,
			gender: getState().appReducer.gender,
			age: getState().appReducer.age,
			balance: getState().appReducer.balance,
			creative_haircut_balance: getState().appReducer.creative_haircut_balance
		}

		let allSum = 0;

		let congratWindow = 0;
		let today = new Date();
		dateLastServ < today.setHours(0, 0, 0, 0) ? turnNum = 1 : turnNum++;
		dispatch(saveTurnNum(turnNum));

		for (const key in services) {

			console.log(services[key].haveSaleBalance)
			if (services[key].haveSaleBalance) {
				dispatch(setDataForAfterOrderPage(services[key].haveSaleBalance, services[key].servName, services[key].servPrice / 2));


				console.log(services[key].haveSaleBalance)
				console.log(userData[services[key].haveSaleBalance])

				if (userData[services[key].haveSaleBalance] === 2) {
					services[key].servPrice /= 2;
					congratWindow = {
						servId: services[key].servId,
						servName: services[key].servName,
						servPrice: services[key].servPrice,
						haveSaleBalance: services[key].haveSaleBalance
					}
					userData[services[key].haveSaleBalance] = 0
				} else {
					userData[services[key].haveSaleBalance]++
				}

			}

			allSum += services[key].servPrice;
		}
		dispatch(saveBalances(userData.balance, userData.creative_haircut_balance))
		console.log(services)


		$.ajax({
			type: "POST",
			async: false,
			url: BASE_URL_API + '/terminal/send_order_data',
			dataType: "json",
			data: 'DATA=' + JSON.stringify({
				accessToken,
				userData,
				services,
				turnNum,
				sale: congratWindow
			}),
			success: data => {
				const printFlowType = getState().authReducer?.config?.printFlowType;
				if (printFlowType === 2) {
					axios.post(
						`${BASE_URL_NODE_API}/terminal/print/${getState().authReducer.workPointId}`,
						{ turnNum, services, allSum, congratWindow }
					)
						.then((data) => {
							console.log(data)
						});
				} else {
					printServer(turnNum, services, allSum, congratWindow);
				}

				if (userData.phone !== 0 && congratWindow) {
					dispatch(setCongratWindowData(true, congratWindow.servName, congratWindow.servPrice));
					setTimeout(() => {
						dispatch(setCongratWindowData(false, '', ''));
						historyObj.push('/after_order');
					}, 10000)
				} else {
					historyObj.push('/after_order');
				}
			},
			error: (error) => {
				subminButtonRef.disabled = false;
				toast.error('Ошибка интернет соединения!');
			}
		});

	}
}

function printServer(turnNum, services, allSum, sale) {
	const body = {
		daterecive: moment().format('DD.MM.YYYY hh:mm:ss'),
		ordernum: 0,
		finalsum: allSum,
		turnnum: turnNum,
		oderswrapper: forPrintNameOrder(services, sale)
	};

	$.ajax({
		type: "POST",
		async: false,
		data: "jsondata=" + JSON.stringify(body),
		url: "http://localhost:3377",
		success: json => {
			console.log("Ответ принтсервера", json);
		}
	});
}

function forPrintNameOrder(services, SALE) {
	let result = "";

	for (const key in services) {
		result += "<div class='brickservrecive'><div class='orders'>" + services[key].servName + "</div><div class='sumorders'>" + services[key].servPrice + "p</div></div>";
	}

	if (SALE) {
		result += "3 стрижка -50 %";
	}

	return result;
}

export const SAVE_TURN_NUM = 'SAVE_TURN_NUM';
function saveTurnNum(turnNum) {
	return {
		type: SAVE_TURN_NUM,
		turnNum
	}
}

export const SET_DATA_FOR_AFTER_ORDER_PAGE = 'SET_DATA_FOR_AFTER_ORDER_PAGE';
function setDataForAfterOrderPage(servId, servName, servPrice) {
	return {
		type: SET_DATA_FOR_AFTER_ORDER_PAGE,
		servId,
		servName,
		servPrice
	}
}

export const SAVE_BALANCES = 'SAVE_BALANCES';
function saveBalances(balance, creative_haircut_balance) {
	return {
		type: SAVE_BALANCES,
		balance,
		creative_haircut_balance
	}
}

export const SET_CONGRAT_WINDOW_DATA = 'SET_CONGRAT_WINDOW_DATA';
export function setCongratWindowData(congratWindowStatus, congratWindowServName, congratWindowServPrice) {
	return {
		type: SET_CONGRAT_WINDOW_DATA,
		congratWindowStatus,
		congratWindowServName,
		congratWindowServPrice
	}
}

export function getHistoryPage() {
	return (dispatch, getState) => {
		$.ajax({
			type: "POST",
			async: false,
			url: BASE_URL_API + '/terminal/get_history_page',
			dataType: "json",
			data: 'DATA=' + JSON.stringify({
				accessToken: getState().authReducer.accessToken,
				phone: getState().appReducer.phone,
				page: getState().appReducer.historyPage
			}),
			success: data => {
				dispatch(setHistory(data));
				if (data.length === 0) {
					dispatch(saveHistoryPage(getState().appReducer.historyPage - 1))
				}
			},
			error: (error) => {
				dispatch(saveHistoryPage(getState().appReducer.historyPage - 1))
			}
		});
	}
}

export const SET_HISTORY_PAGE = 'SET_HISTORY_PAGE';
export function saveHistoryPage(historyPage) {
	if (historyPage < 1) {
		historyPage = 1;
	}
	return {
		type: SET_HISTORY_PAGE,
		historyPage
	}
}

export const SET_HISTORY_ORDERS = 'SET_HISTORY_ORDERS';
export function setHistory(historyOrders) {
	return {
		type: SET_HISTORY_ORDERS,
		historyOrders
	}
}

export function createEmptyOrder() {
	return async (dispatch, getState) => {
		await axios({
			method: 'post',
			url: BASE_URL_API + '/terminal/order/empty',
			headers: {
				'Authorization': 'Bearer ' + getState().authReducer.accessToken
			},
		})
			.then(({ data }) => {
				dispatch(saveTurnNum(data))
			})
			.catch(err => {
				return Promise.reject();
			});
	}
}
