import { useEffect } from 'react';


function Exit(props) {
  useEffect(() => {
    localStorage.clear()
    window.location.replace('/');
  }, [])
  return null
}

export default Exit
