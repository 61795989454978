export function displayPhone(arr, separator) {
    let str = "+";
    for (let i = 0; i < arr.length; i++) {
        switch (i) {
            case 3:
            case 5:
            case 8:
            case 10:
                str += separator;
                str += arr[i];
                break;

            default:
                str += arr[i];
                break;
        }
    }

    return str;
}