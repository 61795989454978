import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

import { appClear } from 'redux/app/action';

import './TimerWindow.scss';

class TimerWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            second: 10,
        }

        this.outerTimerId = null;
        this.innerTimerId = null;
    }

    componentDidMount() {
        this.startOutTimer()
        document.body.addEventListener('click', this.finishOuterTimer)
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.finishOuterTimer)
        clearInterval(this.innerTimerId);
        clearTimeout(this.outerTimerId);
    }

    startOutTimer = () => {
        console.log('startOutTimer');
        this.outerTimerId = setTimeout(() => {
            console.log('startOutTimer - setTimeout');
            // clearTimeout(this.outerTimerId);
            this.setState({ opened: true });
            this.startInnerTimer();
        }, 30000)
    }

    finishOuterTimer = () => {
        if (!this.state.opened) {
            console.log('finishOuterTimer')
            clearTimeout(this.outerTimerId);
            this.outerTimerId = null;
            console.log('oru');
            this.startOutTimer();
        }
    }

    startInnerTimer = () => {
        console.log('startInnerTimer');
        this.innerTimerId = setInterval(() => {
            console.log('startInnerTimer -setInterval');
            if (this.state.second) {
                this.setState((state) => {
                    return { second: state.second - 1 };
                })
            } else {
                this.props.appClear();
                this.props.history.push('/welcome');
            }
        }, 1000)
    }

    finishInnerTimer = (e) => {
        clearInterval(this.innerTimerId);
        this.setState({ opened: false, second: 10 });
        this.startOutTimer();
    }

    stop = (e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation();
    }

    goToWelcome = () => {
        clearInterval(this.innerTimerId);
        clearTimeout(this.outerTimerId);
        this.props.appClear();
    }

    render() {
        if (!this.state.opened) {
            return '';
        }

        return (
            <div id="timer_window_wrapper" onClick={this.stop} >

                <div class="timer_window" >
                    <h3>Вам еще требуется время?</h3>
                    <div class="timer">{this.state.second}</div>

                    <div className="btn_container">
                        <div class="yes" onClick={this.finishInnerTimer}>ДА</div>
                        <div class="not" onClick={this.finishInnerTimer}>НЕТ</div>
                        <Link to='/welcome' class="backtomain" onClick={this.goToWelcome}>Вернуться в главное меню</Link>
                    </div>
                </div>

            </div>

        )
    }
}


const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    appClear
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TimerWindow))