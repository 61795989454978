import Balloon from 'assets/img/Balloon.png';
import Border from 'assets/img/Border.png';
import './CongratWindow.scss';

const CongratWindow = ({ opened, servName, servPrice }) => {
	if (!opened) return null;

	return (
		<div id='congrat_window_wrapper'>
			<div class='congrat_window' >
				<img src={Balloon} class='balloon' alt=''/>
				<img src={Border} class='border' alt=''/>
				<h3>УРА!</h3>
				<p class='onep'>3-ая <span class='nameServForcongratulationForRegUser'>{servName.toLowerCase()}</span></p>
				<p class='twop'>за <span class='threered'>{servPrice} рубля</span></p>
				<h2>ПОЗДРАВЛЯЕМ!</h2>
			</div>
		</div>
	)
}

export default CongratWindow
