import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { checkPhone, appClear } from 'redux/app/action';
import { checkToken } from 'redux/auth/action';
import { displayPhone } from 'assets/js/functions';
import Keyboard from 'components/Keyboard/Keyboard';
import TimerWindow from 'components/TimerWindow/TimerWindow';
import './Phone.scss';

const PhoneStyledWrapper = styled.div`
	h1 {
		margin-top: ${({ deviceType }) => deviceType === 'tablet' ? '10px' : '135px'};
	}

	.inputfield {
		margin: ${({ deviceType }) => deviceType === 'tablet' ? '25px auto 25px' : '60px auto 30px'};
	}

	.to_back {
		${({ deviceType }) => deviceType === 'tablet' ? 'margin-bottom: -50px;' : ''}
	}

	.phone_next {
		margin:  ${({ deviceType }) => deviceType === 'tablet' ? ' 15px 0 0' : '32px'};
		${({ deviceType }) => deviceType === 'tablet' ? 'margin-bottom: -50px;' : ''}
	}
`;

const Phone = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { config } = useSelector(state => state.authReducer);
	const { deviceType } = config;

	const [phone, setPhone] = useState([3, 7, 5]);
	const [touched, setTouched] = useState(false)

	useEffect(() => {
		checkToken();
		document.title = 'Введите номер телефона | TOTO';
	}, [])

	const getValue = (phoneArr) => {
		if (!touched) setTouched(true);
		setPhone([...phoneArr]);
	}

	const phoneNext = () => {
		if (phone.length !== 12) return;
		dispatch(checkPhone(phone.join(''), history));
	}

	return (
		<PhoneStyledWrapper deviceType={deviceType} id='phone'>

			<h1>Введите номер телефона</h1>
			<div class='inputfield'>{touched ? displayPhone(phone, ' ') : <>+375 <span class='inputfieldspan'>29 111 22 33</span></>}</div>

			<Keyboard callBack={getValue} valuesArr={phone} />

			<div className='btn_container'>
				<Link to='/' onClick={() => dispatch(appClear())} className='to_back'>{'<'} НАЗАД</Link>
				<button style={phone.length === 12 ? { color: '#b30101' } : { color: '#c3c3c3' }} onClick={phoneNext} className='phone_next'>
					ПРОДОЛЖИТЬ
				</button>
			</div>

			<TimerWindow />

		</PhoneStyledWrapper>
	)
}

export default Phone;
