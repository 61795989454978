import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { checkToken } from 'redux/auth/action';
import { saveGenderAge } from 'redux/app/action';
import TimerWindow from 'components/TimerWindow/TimerWindow';
import './GenderAge.scss';

const GenderAgeStyledWrapper = styled.form`
	.gender {
		margin-top: ${({ deviceType }) => deviceType === 'tablet' ? '15px' : '25px'};
	}

	.box {
		width: ${({ deviceType }) => deviceType === 'tablet' ? '220px' : '251px'};
		height: ${({ deviceType }) => deviceType === 'tablet' ? '450px' : '542px'};
	}

	.age {
		margin-top: ${({ deviceType }) => deviceType === 'tablet' ? '15px' : '25px'};
	}

	.agebricks {
		width: ${({ deviceType }) => deviceType === 'tablet' ? '350px' : '350px'};
		height: ${({ deviceType }) => deviceType === 'tablet' ? '45px' : '60px'};
		font-size: ${({ deviceType }) => deviceType === 'tablet' ? '22px' : '28px'};
		margin-bottom: ${({ deviceType }) => deviceType === 'tablet' ? '19px' : '19.5px'};
	}

	.to_back, .gender_next {
		${({ deviceType }) => deviceType === 'tablet' ? 'margin-bottom: -50px;' : ''}
	}
`;

const GenderAge = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { config } = useSelector(state => state.authReducer);
	const { deviceType } = config;

	const [gender, setGender] = useState();
	const [age, setAge] = useState();

	useEffect(() => {
		checkToken();
		document.title = 'Выбор пола и возраста | TOTO';
	}, [])

	const genderNext = (e) => {
		e.preventDefault();

		if (gender && age) {
			dispatch(saveGenderAge(gender, age));
			history.push('/services');
		}
	}

	const onChangeGender = (event) => {
		setGender(event.target.value);
	}

	const onChangeAge = (event) => {
		setAge(event.target.value);
	}

	const ageArr = ['до 10 лет', '11-20 лет', '21-30 лет', '31-40 лет', '41-50 лет', '51-60 лет', '60+ лет'];

	return (
		<GenderAgeStyledWrapper deviceType={deviceType} id='gender_age' onSubmit={genderNext}>
			<div className='gender'>
				<h2>Выберите ваш пол <br /> <span>(нажмите на нужный блок)</span></h2>
				<div className='gender_boxes'>
					<label>
						<input type='radio' name='gender' value='мужской' id='male' onChange={onChangeGender} />
						<div className='box male'>
							<h3>Мужской</h3>
						</div>
					</label>

					<label>
						<input type='radio' name='gender' value='женский' id='female' onChange={onChangeGender} />
						<div className='box female'>
							<h3>Женский</h3>
						</div>
					</label>
				</div>

			</div>
			<div className='age'>
				<h2>Выберите возраст</h2>
				{ageArr.map((value, index) => (
					<label key={index}>
						<input type='radio' name='age' value={value} onChange={onChangeAge} />
						<div className='agebricks'>{value}</div>
					</label>
				))}
			</div>


			<div className='bnt_block'>
				<Link to='/phone' className='to_back'>{'<'} НАЗАД</Link>
				<button style={gender && age ? { color: '#b30101' } : { color: '#c3c3c3' }} type='submit' className='gender_next'>
					ПРОДОЛЖИТЬ
				</button>
			</div>

			<TimerWindow />
		</GenderAgeStyledWrapper>
	)
}

export default GenderAge;
