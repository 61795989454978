import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import styled from 'styled-components';

import { sendOrderData, getTurnData } from 'redux/app/action';
import { checkToken } from 'redux/auth/action';
import TimerWindow from 'components/TimerWindow/TimerWindow';
import CongratWindow from 'components/CongratWindow/CongratWindow';
import './Services.scss';

const ServicesStyledWrapper = styled.form`
	h2 {
		margin: ${({ deviceType }) => deviceType === 'tablet' ? '0 auto 20px' : '0 auto 33px'};
	}

	.serv__data {
		height: ${({ deviceType }) => deviceType === 'tablet' ? '100px' : '110px'};
		font-size: ${({ deviceType }) => deviceType === 'tablet' ? '24px' : '25px'};
	}

	.services_main_block {
		margin-bottom: ${({ deviceType }) => deviceType === 'tablet' ? '30px' : '25px'};
	}

	.services_main_block__right {
		.serv:first-child  .serv__data{
				height: ${({ deviceType }) => deviceType === 'tablet' ? '350px' : '384px'};
		}
	}

	.services_extra_block {
		margin-bottom: ${({ deviceType }) => deviceType === 'tablet' ? '10px' : '27px'};
	}

	.to_back {
		${({ deviceType }) => deviceType === 'tablet' ? 'margin-bottom: -50px;' : ''}
	}

	.services_next {
		margin:  ${({ deviceType }) => deviceType === 'tablet' ? ' 15px' : '25px'};
		${({ deviceType }) => deviceType === 'tablet' ? 'margin-bottom: -50px;' : ''}
	}
`;

const ServicesItem = ({ callBack, servId, servName, servPrice, haveSaleBalance, servCode }) => {
	return (
		<label className='serv'>
			<input
				type="checkbox"
				onChange={callBack}
				key={servId}
				data-serv-id={servId}
				data-serv-name={servName}
				data-serv-price={servPrice}
				data-have-sale-balance={haveSaleBalance} />
			<div className="serv__data">
				<div className="serv_name" dangerouslySetInnerHTML={{ __html: servCode }}></div>
				<div className="serv_price">{servPrice} р</div>
			</div>
		</label>
	)
}
class Services extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			services: {}
		}

		this.subminButtonRef = React.createRef();
	}

	componentDidMount() {
		this.props.checkToken();
		document.title = 'Выбор услуг | ТОТО!';
		this.props.getTurnData();
	}

	servicesNext = (event) => {
		event.preventDefault();

		if (Object.keys(this.state.services).length === 0) {
			return
		}

		this.props.sendOrderData(this.state.services, this.props.history, this.subminButtonRef.current);
	}

	radio = (e) => {
		let state = e.target.checked;
		let servIdTarget = +e.target.dataset.servId;
		let servData = {
			servId: servIdTarget,
			servName: e.target.dataset.servName,
			servPrice: +e.target.dataset.servPrice,
			haveSaleBalance: e.target.dataset.haveSaleBalance
		}

		let arr = document.querySelectorAll('.services_main_block input');

		for (let i = 0; i < arr.length; i++) {
			let servId = arr[i].dataset.servId;
			this.deleteServToState(servId);
			arr[i].checked = false;
		}

		if (state) {
			e.target.checked = true;
			this.addServToState(servIdTarget, servData);
		} else {
			e.target.checked = false;
		}
	}

	pusher = (e) => {
		let servId = +e.target.dataset.servId;
		let servData = {
			servId,
			servName: e.target.dataset.servName,
			servPrice: +e.target.dataset.servPrice,
			haveSaleBalance: e.target.dataset.haveSaleBalance
		}
		if (this.state.services[servId]) {
			this.deleteServToState(servId);
		} else {
			this.addServToState(servId, servData);
		}
	}

	addServToState = (servId, servData) => {
		this.setState(prevState => {
			let services = Object.assign({}, prevState.services);
			services[servId] = servData;
			return { services };
		})
	}

	deleteServToState = (servId) => {
		this.setState(prevState => {
			let services = Object.assign({}, prevState.services);
			delete (services[servId]);
			return { services };
		})
	}

	render() {
		const { deviceType } = this.props.config;

		return (
			<ServicesStyledWrapper deviceType={deviceType} id='services' onSubmit={this.servicesNext}>

				{deviceType === 'tablet' ? '' : <h2>Стрижки</h2>}
				<div className="services_main_block" >
					<div className="services_main_block__right">
						{this.props.servicesListArr.slice(0, 1).map(value => <ServicesItem callBack={this.radio} servId={value.id} servName={value.name} servPrice={value.price} servCode={value.code} haveSaleBalance={value.have_sale_balance} />)}
					</div>

					<div className="services_main_block__left">
						{this.props.servicesListArr.slice(1, 4).map(value => <ServicesItem callBack={this.radio} servId={value.id} servName={value.name} servPrice={value.price} servCode={value.code} haveSaleBalance={value.have_sale_balance} />)}
					</div>
				</div>

				<h2>Дополнительные услуги</h2>
				<div className="services_extra_block">
					{this.props.servicesListArr.slice(4, 7).map(value => <ServicesItem callBack={this.pusher} servId={value.id} servName={value.name} servPrice={value.price} servCode={value.code} haveSaleBalance={value.have_sale_balance} />)}
				</div>

				{/* <div className="services_styling_block">
					<div className="hair_styling">
						<span> Укладка волос + укладочные средства</span>
					</div>
					{this.props.servicesListArr.slice(7, 10).map(value => <ServicesItem callBack={this.pusher} servId={value.id} servName={value.name} servPrice={value.price} servCode={value.code} haveSaleBalance={value.have_sale_balance} />)}
				</div> */}

				<div className="btn_container">
					<div className="to_back" onClick={this.props.history.goBack}>{"<"} НАЗАД</div>
					<button
						style={Object.keys(this.state.services).length !== 0 ? { color: '#b30101' } : { color: '#c3c3c3' }}
						className="services_next"
						type='submit'
						ref={this.subminButtonRef}>
						РАСПЕЧАТАТЬ ЧЕК
					</button>
				</div>

				<CongratWindow opened={this.props.congratWindowStatus} servName={this.props.congratWindowServName} servPrice={this.props.congratWindowServPrice} />
				<TimerWindow />
			</ServicesStyledWrapper>
		)
	}
}

const mapStateToProps = ({ authReducer, appReducer }) => {
	return {
		servicesListArr: authReducer.servicesListArr,
		config: authReducer.config,

		congratWindowStatus: appReducer.congratWindowStatus,
		congratWindowServName: appReducer.congratWindowServName,
		congratWindowServPrice: appReducer.congratWindowServPrice
	}
}

const mapDispatchToProps = {
	sendOrderData,
	getTurnData,
	checkToken
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Services));
