import './Keyboard.scss';

const Keyboard = ({ valuesArr, callBack }) => {
	const keyboardClick = (event) => {
		let newValuesArr = valuesArr;

		if (newValuesArr.length === 12 && event.target.dataset.btn !== 'backspace' && event.target.dataset.btn !== 'clearAll') {
			return;
		}
		if (event.target.dataset.btn === 'backspace') {
			newValuesArr.pop();
			callBack(newValuesArr);
			return;
		}
		if (event.target.dataset.btn === 'clearAll') {
			newValuesArr.length = 0;
			callBack(newValuesArr);
			return;
		}
		if (event.target.className === 'btn') {
			newValuesArr.push(+event.target.innerText);
			callBack(newValuesArr);
			return;
		}
	}

	return (
		<div class='keyboard' onClick={keyboardClick}>
			<div class='btn'>1</div>
			<div class='btn'>2</div>
			<div class='btn'>3</div>
			<div class='btn'>4</div>
			<div class='btn'>5</div>
			<div class='btn'>6</div>
			<div class='btn'>7</div>
			<div class='btn'>8</div>
			<div class='btn'>9</div>
			<div class='btn' data-btn='clearAll'>C</div>
			<div class='btn'>0</div>
			<div class='btn' data-btn='backspace'></div>
		</div>
	)
}

export default Keyboard
