import {
    SAVE_PHONE,
    SAVE_GENDER_AGE,
    APP_CLEAR,
    SAVE_TURN_DATA,
    SET_CONGRAT_WINDOW_DATA,
    SET_DATA_FOR_AFTER_ORDER_PAGE,
    SAVE_BALANCES,
    SAVE_TURN_NUM,
    SET_TO_HISTORY_ROUT,
    SET_HISTORY_PAGE,
    SET_HISTORY_ORDERS
} from './action';

const initialState = {
    phone: +localStorage.getItem('phone') || 0,
    gender: localStorage.getItem('gender') || 0,
    age: localStorage.getItem('age') || 0,

    balance: +localStorage.getItem('balance') || 0,
    creative_haircut_balance: +localStorage.getItem('creative_haircut_balance') || 0,

    turnNum: +localStorage.getItem('turnNum') || 0,
    dateLastServ: +localStorage.getItem('dateLastServ') || 0,

    congratWindowStatus: false,
    congratWindowServName: '',
    congratWindowServPrice: '',


    forAfterOrderPageServId: +localStorage.getItem('forAfterOrderPageServId') || 'balance',
    forAfterOrderPageServName: localStorage.getItem('forAfterOrderPageServName') || 'Стрижка',
    forAfterOrderPageServPrice: localStorage.getItem('forAfterOrderPageServPrice') || '3.5',

    toHistoryRout: +localStorage.getItem('toHistoryRout') || false,
    historyPage: +localStorage.getItem('historyPage') || 1,
    historyOrders: []
}

const routingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PHONE:
            localStorage.setItem('phone', action.phone);
            return { ...state, phone: action.phone }

        case SAVE_GENDER_AGE:
            localStorage.setItem('gender', action.gender);
            localStorage.setItem('age', action.age);
            return {
                ...state,
                gender: action.gender,
                age: action.age
            }

        case SAVE_TURN_DATA:
            localStorage.setItem('turnNum', action.turnNum);
            localStorage.setItem('dateLastServ', action.dateLastServ);
            return {
                ...state,
                turnNum: action.turnNum,
                dateLastServ: action.dateLastServ,
            }

        case APP_CLEAR:
            localStorage.setItem('gender', 0);
            localStorage.setItem('age', 0);
            localStorage.setItem('phone', 0);
            localStorage.setItem('balance', 0);
            localStorage.setItem('creative_haircut_balance', 0);
            localStorage.setItem('turnNum', 0);
            localStorage.setItem('dateLastServ', 0);
            localStorage.setItem('forAfterOrderPageServId', 1);
            localStorage.setItem('forAfterOrderPageServName', 'Стрижка');
            localStorage.setItem('forAfterOrderPageServPrice', '3.5');
            localStorage.setItem('toHistoryRout', false);
            localStorage.setItem('historyPage', 1);
            return {
                ...state,
                gender: 0,
                age: 0,
                phone: 0,
                balance: 0,
                creative_haircut_balance: 0,
                turnNum: 0,
                dateLastServ: 0,
                congratWindowStatus: false,
                congratWindowServName: '',
                congratWindowServPrice: '',
                forAfterOrderPageServId: 1,
                forAfterOrderPageServName: 'Стрижка',
                forAfterOrderPageServPrice: '3.5',
                toHistoryRout: false,
                historyPage: 1,
                historyOrders: []
            }

        case SET_CONGRAT_WINDOW_DATA:
            return {
                ...state,
                congratWindowStatus: action.congratWindowStatus,
                congratWindowServName: action.congratWindowServName,
                congratWindowServPrice: action.congratWindowServPrice
            }

        case SET_DATA_FOR_AFTER_ORDER_PAGE:
            localStorage.setItem('forAfterOrderPageServId', action.servId);
            localStorage.setItem('forAfterOrderPageServName', action.servName);
            localStorage.setItem('forAfterOrderPageServPrice', action.servPrice);
            return {
                ...state,
                forAfterOrderPageServId: action.servId,
                forAfterOrderPageServName: action.servName,
                forAfterOrderPageServPrice: action.servPrice
            }

        case SAVE_BALANCES:
            localStorage.setItem('balance', action.balance);
            localStorage.setItem('creative_haircut_balance', action.creative_haircut_balance);
            return {
                ...state,
                balance: action.balance,
                creative_haircut_balance: action.creative_haircut_balance
            }

        case SAVE_TURN_NUM:
            localStorage.setItem('turnNum', action.turnNum);
            return { ...state, turnNum: action.turnNum }

        case SET_TO_HISTORY_ROUT:
            localStorage.setItem('toHistoryRout', action.status);
            return { ...state, toHistoryRout: action.status }

        case SET_HISTORY_PAGE:
            localStorage.setItem('historyPage', action.historyPage);
            return { ...state, historyPage: action.historyPage }

        case SET_HISTORY_ORDERS:
            return { ...state, historyOrders: action.historyOrders }

        default:
            return state;
    }
}

export default routingReducer
