import $ from 'jquery';
import { BASE_URL_API } from 'assets/js/baseUrl';

export function getServicesList(accessToken){
    return (dispatch) => {
        $.ajax({
            type: 'POST',
            async: false,
            url: BASE_URL_API + '/terminal/get_services_list',
            dataType: 'json',
            data: 'DATA=' +  JSON.stringify({ accessToken }),
            success: data => {
                dispatch(saveServicesList(data))
            },
            error: (error) => {
                console.log(error);
            }
        });
    }
}

export const SAVE_SERVICES_LIST = 'SAVE_SERVICES_LIST';
export function saveServicesList(servicesListArr){
    return {
        type: SAVE_SERVICES_LIST,
        servicesListArr
    }
}

export const SEVE_CONFIG = 'SEVE_CONFIG';
export function saveConfig(data){
    return {
        type: SEVE_CONFIG,
        data
    }
}

export const AUTH__SAVE_TOKENS = 'AUTH__SAVE_TOKENS';
export function saveTokens(tokens) {
    return {
        type: AUTH__SAVE_TOKENS,
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        expiresIn: tokens.expiresIn,
    }
}

export const AUTH__DATA_FROM_TOKEN = 'AUTH__DATA_FROM_TOKEN';
export function saveDataFromToken(accessToken) {
    accessToken = JSON.parse(atob(accessToken.split('.')[1]));
    return {
        type: AUTH__DATA_FROM_TOKEN,
        workPointId: +accessToken.workPointData,
        // workPointName: accessToken.workPointData.name
    }
}

export function checkToken() {
    return () => {}
}

export function logout() {
    console.log('logout')
    localStorage.clear();
    window.location.replace('/');
}
