import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { checkToken } from 'redux/auth/action';
import { appClear, getHistoryPage, saveHistoryPage } from 'redux/app/action';
import { displayPhone } from 'assets/js/functions';
import './History.scss';

const HistoryStyledWrapper = styled.div`
	h1 {
		font-size: ${({ deviceType }) => deviceType === 'tablet' ? '2em' : '3em'};
		margin: ${({ deviceType }) => deviceType === 'tablet' ? '10px 0 10px' : '1.5em 0 1em'};
	}

	.to_welcome_form_history {
		margin: ${({ deviceType }) => deviceType === 'tablet' ? '55px auto -50px' : '55px auto'};
	}
`;

const History = () => {
	const dispatch = useDispatch();
	const { config } = useSelector(state => state.authReducer);
	const { deviceType, terminalFlowType } = config;
	const { phone, historyPage, historyOrders } = useSelector(state => state.appReducer);

	useEffect(() => {
		checkToken();
		document.title = `История заказов по номеру телефона: ${displayPhone(String(phone).split(''), '-')} | TOTO`;
	}, [phone])

	useEffect(() => {
		dispatch(getHistoryPage());
	}, [historyPage, dispatch])

	const nextPage = () => {
		dispatch(saveHistoryPage(historyPage + 1));
	}

	const backPage = () => {
		dispatch(saveHistoryPage(historyPage - 1));
	}

	return (
		<HistoryStyledWrapper deviceType={deviceType} id='history'>

			<h1>История заказов по номеру телефона: <br />{displayPhone(String(phone).split(''), '-')}</h1>

			<table>
				<thead>
					<tr>
						<th>Номер заказа</th>
						<th>Услуги</th>
						<th>Дата заказа</th>
						<th>Сумма заказа</th>
						<th>Статус заказа</th>
					</tr>
				</thead>
				<tbody>
					{historyOrders.map(value => (
						<tr>
							<td>{value.id}</td>
							<td>{value.services}</td>
							<td>{value.date_time}</td>
							<td>{value.sum}</td>
							<td>{value.status}</td>
						</tr>
					))}
				</tbody>
			</table>

			<nav>
				<button onClick={backPage} className='to_back'>{'<'} Предведущая</button>

				{terminalFlowType !== 2 &&
					<Link className='to_serv_form_history' to='/services'>Перейти к заказу услуг</Link>
				}

				<button onClick={nextPage} className='to_next'>Следующая {'>'}</button>
			</nav>

			<Link onClick={() => dispatch(appClear())} to='/welcome' className='to_welcome_form_history' >Вернуться на главную</Link>
		</HistoryStyledWrapper>
	)
}

export default History
