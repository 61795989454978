import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';

import App from './App';
import * as serviceWorker from './serviceWorker';

import {compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from 'redux/rootReducer';
import {jwt} from './refreshToken';

import 'assets/fonts/fonts.css';
import './index.css';

const store = createStore(rootReducer, compose( applyMiddleware(jwt, thunk))); //(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : [])

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

