import { logout, saveTokens } from 'redux/auth/action';

let BASE_URL_AUTH = 'http://auth';

if(process.env.NODE_ENV === 'production'){
    BASE_URL_AUTH = 'https://auth.totoopen.by/public';
}

const PATH__REFRESH_TOKEN = BASE_URL_AUTH + '/api/v1/terminal/refresh_token';


export function jwt({ dispatch, getState }) {

    return (next) => (action) => {

        console.log(process.env.NODE_ENV)
        if (typeof action === 'function') {
            console.log(action.custom);
            if (getState().authReducer.login && getState().authReducer.accessToken ) {
                console.log('llll');
                console.log(`LAST SEC: ${( getState().authReducer.expiresIn * 1000 - Date.now()) /1000 }`)
                if (+Date.now() >= getState().authReducer.expiresIn * 1000-10000) {

                  //  make sure we are not already refreshing the token
                    if (!getState().authReducer.freshTokenPromise) {
                        return refreshToken(dispatch, getState().authReducer.refreshToken).then(() => next(action));
                    } else {
                        return getState().authReducer.freshTokenPromise.then(() => next(action));
                    }
                }
            }
            else {
                // if(action == action){
                //     console.log(3);
                // }
                // if(action ==action){
                //     console.log(3);
                // }
                // if(!(action.name === 'loginAction')){
                    console.log(2);
                   logout();
                // }
               }
        }
        return next(action);
    };
}

export function refreshToken(dispatch, rf) {
console.log(rf)
    var freshTokenPromise = fetch(PATH__REFRESH_TOKEN, {
        method: 'POST',
        
        body: "DATA=" + JSON.stringify({ refreshToken: rf})
    }).then(response => response.json())
        .then(t => {
            dispatch({
                type: 'DONE_REFRESHING_TOKEN'
            });

            dispatch(saveTokens(t));
            console.log(t);
            return t ? Promise.resolve(t.accessToken) : Promise.reject({
                message: 'could not refresh token'
            });
        })
        .catch(e => {
            
            console.log('error refreshing token', e);
            logout();
            dispatch({
                type: 'DONE_REFRESHING_TOKEN'
            });
            return Promise.reject(e);
        });



    dispatch({
        type: 'REFRESHING_TOKEN',

        // we want to keep track of token promise in the state so that we don't try to refresh
        // the token again while refreshing is in process
        freshTokenPromise
    });

    return freshTokenPromise;
}