import {
    AUTH__SAVE_TOKENS,
    AUTH__DATA_FROM_TOKEN,
    SAVE_SERVICES_LIST,
    SEVE_CONFIG,
} from './action';

const initialState = {
    login: localStorage.login || false,
    accessToken: localStorage.accessToken || '',
    refreshToken: localStorage.refreshToken || '',
    expiresIn: +localStorage.expiresIn || '',

    workPointId: localStorage.accessToken ? +JSON.parse(atob(localStorage.accessToken.split('.')[1])).workPointData : null,
    // workPointName: localStorage.accessToken ? JSON.parse(atob(localStorage.accessToken.split('.')[1])).workPointData.name : '',

    freshTokenPromise: '',

    servicesListArr: localStorage.servicesListArr ? JSON.parse(localStorage.servicesListArr) : [],

    config: localStorage.config ? JSON.parse(localStorage.config) : {},
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REFRESHING_TOKEN':
            return {
                ...state,
                freshTokenPromise: action.freshTokenPromise
            }

        case 'DONE_REFRESHING_TOKEN':
            return {
                ...state,
                freshTokenPromise: ''
            }

        case AUTH__SAVE_TOKENS:
            localStorage.setItem('accessToken', action.accessToken);
            localStorage.setItem('refreshToken', action.refreshToken);
            localStorage.setItem('expiresIn', action.expiresIn);
            localStorage.setItem('login', true);
            return {
                ...state,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                expiresIn: action.expiresIn,
                login: true,
            }

        case AUTH__DATA_FROM_TOKEN:
            return {
                ...state,
                workPointId: action.workPointId,
                workPointName: action.workPointName
            }
  
        case SAVE_SERVICES_LIST:
            localStorage.setItem('servicesListArr', JSON.stringify(action.servicesListArr));
            return { ...state, servicesListArr: action.servicesListArr }

        case SEVE_CONFIG:
            localStorage.setItem('config', JSON.stringify(action.data));
            return { ...state, config: action.data }

        default:
            return state;
    }
}

export default authReducer
