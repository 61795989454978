const AUTH_VERSION = 'v1';
const API_VERSION = 'v1';
const API_NODE_VERSION = 'v1';

let BASE_URL_AUDIO = 'https://api.totoopen.by/audio/';
let BASE_URL_AUTH = 'http://totoTotoAuthApi/public/api/' + AUTH_VERSION;
let BASE_URL_API = 'http://totoTotoMainApi/public/api/' + API_VERSION;
let BASE_URL_NODE_API = 'http://localhost:5000' + API_NODE_VERSION;


if (process.env.NODE_ENV === 'production') {
    BASE_URL_AUTH = 'https://auth.totoopen.by/public/api/' + AUTH_VERSION;
    BASE_URL_API = 'https://api.totoopen.by/public/api/' + API_VERSION;
    BASE_URL_NODE_API = 'https://arcane-inlet-91525.herokuapp.com/api'
}

export { BASE_URL_AUTH, BASE_URL_API, BASE_URL_AUDIO, BASE_URL_NODE_API }
