import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Privacy.scss';

const Privacy = () => {
	useEffect(() => {
		document.title = 'Политика конфиденциальности | ТОТО';
	}, [])

	return (
		<div id='privacy'>
			<div className='text'>
				<p>
					Настоящая Политика конфиденциальности регулирует порядок обработки и использования персональных и иных данных сайтов https://totoopen.by., http://toto1.beget.tech (далее — ТОТО Японская парикмахерская).
					Целью настоящей Политики является обеспечение надлежащей защиты информации о Пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.<br />
					Отношения, связанные со сбором, хранением, распространением и защитой информации предоставляемой Пользователем, регулируются настоящей Политикой, иными официальными документами и действующим законодательством Республики Беларусь.<br />
					Используя Сайт и Сервисы Пользователь выражает свое полное согласие с условиями настоящей Политики.
					В случае несогласия Пользователя с условиями настоящей Политики использование Сайта и/или каких-либо Сервисов доступных при использовании Сайта должно быть немедленно прекращено.
				</p>

				<h2>1. Термины</h2>
				<p>1.1. Сайт — сайт, расположенный в сети Интернет по адресу https://totoopen.by., http://toto1.beget.tech<br />
					1.2. Пользователь — лицо использующее Сайт.<br />
					1.3. Законодательство — действующее законодательство Республики Беларусь.<br />
					1.4. Персональные данные — персональные данные Пользователя, которые Пользователь предоставляет о себе самостоятельно или в процессе использования функционала Сайта.<br />
					1.5. Данные — иные данные о Пользователе (не входящие в понятие Персональных данных).<br />
					1.6. Услуга(и) — услуги, предоставляемые ТОТО Японская парикмахерская.</p>

				<h2>2. Сбор и обработка персональных данных</h2>
				<p>
					2.1. ТОТО Японская парикмахерская собирает и хранит только те Персональные данные, которые необходимы для оказания Услуг ТОТО Японская парикмахерская и взаимодействия с Пользователем. <br />
					2.2. Персональные данные могут использоваться в следующих целях:<br />
					2.2.1 оказание Услуг Пользователю, в том числе в целях получения Пользователем рекламы посредством звонков, смс, сообщений viber и похожих сервисов, таргетированной рекламы;<br />
					2.2.2 идентификация Пользователя;<br />
					2.2.3 взаимодействие с Пользователем;<br />
					2.2.4 направление Пользователю рекламных материалов, информации и запросов;<br />
					2.2.5 проведение статистических и иных исследований;<br />
					2.3. ТОТО Японская парикмахерская в том числе обрабатывает следующие данные:<br />
					2.3.1 фамилия, имя и отчество;<br />
					2.3.2 пол, возраст;<br />
					2.3.3 адрес электронной почты;<br />
					2.3.4 номер мобильного телефона;<br />
					2.3.5 город;<br />
					2.3.6 выбранные услуги<br />
					2.3.7 данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы.<br />
					2.4. Пользователю запрещается указывать на Сайте персональные данные третьих лиц.
				</p>


				<h2>3. Порядок обработки персональных и иных данных</h2>
				<p>
					3.1. ТОТО Японская парикмахерская обязуется использовать Персональные данные в соответствии с действующим законодательством Республики Беларусь.<br />
					3.2. В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме случаев, когда указанные данные являются общедоступными.<br />
					3.3. ТОТО Японская парикмахерская имеет право сохранять архивную копию Персональных данных и Данных.<br />
					3.4. ТОТО Японская парикмахерская имеет право передавать Персональные данные и Данные Пользователя без согласия Пользователя следующим лицам:<br />
					3.4.1 государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по их мотивированному запросу;<br />
					3.4.2 партнерам ТОТО Японская парикмахерская;<br />
					3.4.3 в иных случаях, прямо предусмотренных действующим законодательством РБ.<br />
					3.5. ТОТО Японская парикмахерская имеет право передавать Персональные данные и Данные третьим лицам, не указанным в п. 3.4. настоящей Политики конфиденциальности, в следующих случаях:<br />
					3.5.1 Пользователь выразил свое согласие на такие действия;<br />
					3.5.2 передача необходима в рамках использования Пользователем Сайта или оказания Услуг Пользователю;<br />
					3.5.3 передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики.<br />
					3.6. ТОТО Японская парикмахерская осуществляет автоматизированную обработку Персональных данных и Данных.
				</p>

				<h2>Форма согласия потенциальных клиентов Заказчика на обработку их персональных данных</h2>
				<p>
					Распечатывая талон очереди на услугу ТОТО Японская парикмахерской, Вы как клиент ТОТО Японская парикмахерская (далее – Клиент), даете согласие:<br />
					1.1. На обработку своих персональных данных, включая сбор, систематизацию, накопление, хранение, обработку, уточнение (обновление, изменение), сопоставление, использование, распространение (в том числе передача), обезличивание, блокирование, уничтожение, трансграничную передачу персональных данных, а также осуществление любых иных действий с персональными данными с учетом действующего законодательства, в том числе на передачу персональных данных Клиента и сведений о выбранной услуге или услугах ТОТО Японская парикмахерская третьим лицам, привлекаемым ТОТО Японская парикмахерская для:
				</p>

				<ul>
					<li>выполнения звонков Клиенту,
					</li>
					<li>направления рекламных предложений ТОТО Японская парикмахерская, а также выполнения иных не противоправных действий, направленных на выполнение услуг ТОТО Японская парикмахерская.</li>
				</ul>

				<p> 1.2. На получение рекламных предложений от ТОТО Японская парикмахерская.<br />
					1.3. На обработку информации, указанной выше и переданной третьим лицам, а также информации о Клиенте (включая персональные данные), имеющейся в распоряжении третьих лиц, и получение ТОТО Японская парикмахерская информации о Клиенте от третьих лиц с правом ее дальнейшей обработки согласно п.1.1. настоящего согласия.<br />
					1.4. На осуществление аудиозаписи телефонных переговоров, видеозаписи и аудиозаписи в помещении ТОТО Японская парикмахерская и на использование данных материалов в качестве доказательств в случае возникновения спора.
					Настоящее согласие действует до момента его отзыва Клиентом путем направления соответствующего письменного уведомления ТОТО Японская парикмахерская по следующему адресу электронной почты: totogomel@yandex.by
				</p>

				<h2>4. Защита персональных данных</h2>
				<p>
					4.1. ТОТО Японская парикмахерская осуществляет надлежащую защиту Персональных и иных данных в соответствии с Законодательством и принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных. <br />
					4.2. Применяемые меры защиты в том числе позволяют защитить Персональные данные от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ними третьих лиц.
				</p>

				<h2>5. Иные положения</h2>
				<p>
					5.1. К настоящей Политике конфиденциальности и отношениям между Пользователем и ТОТО Японская парикмахерская, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Республики Беларусь.<br />
					5.2. Все возможные споры, вытекающие из настоящего Соглашения, подлежат разрешению в соответствии с действующим законодательством по месту регистрации ТОТО Японская парикмахерская.<br />
					Перед обращением в суд Пользователь должен соблюсти обязательный досудебный порядок и направить ТОТО Японская парикмахерская соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.<br />
					5.3. Если по тем или иным причинам одно или несколько положений Политики конфиденциальности будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Политики конфиденциальности.<br />
					5.4. ТОТО Японская парикмахерская имеет право в любой момент изменять Политику конфиденциальности (полностью или в части) в одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают в силу на следующий день после размещения на Сайте.<br />
					5.5. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на странице по адресу http://toto1.beget.tech/privacy.
				</p>

				<h2>6. Обратная связь. Вопросы и предложения</h2>
				<ul>
					<li>Все предложения или вопросы по поводу настоящей Политики следует сообщать в Службу поддержки и работы с клиентами по электронной почте totogomel@yandex.by</li>
					<li>Пользователь обязуется самостоятельно следить за изменениями Политики конфиденциальности путем ознакомления с актуальной редакцией.</li>
				</ul>

				<h2>7. Контактная информация ТОТО Японская парикмахерская</h2>
				<ul>
					<li>Телефон: +375 29 304 93 98</li>
					<li>E-mail: totogomel@yandex.by</li>
					<li>Почтовый адрес: 246049 Республика Беларусь, г. Гомель, ул. 60 лет СССР, 14, пом 7.</li>
				</ul>
			</div>

			<Link class='toindexfromprivacy' to='/welcome'>назад</Link>
		</div>
	)
}

export default Privacy
